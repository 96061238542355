import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { VueAxios } from './utils/request'
import App from './App.vue'
import router from './router'
import store from './store'
import {
  Button,
  Layout,
  Menu,
  ConfigProvider,
  Icon,
  Row,
  Col,
  Affix,
  Popover,
  message,
  notification,
  Card,
  Carousel,
  Divider,
  Result,
  Spin,
  Table,
  Drawer,
} from 'ant-design-vue'
import enUS from './locale/enUS'
import zhCN from './locale/zhCN'
import queryString from 'query-string'
import '@/permission' // permission control

Vue.config.productionTip = false

Vue.prototype.$message = message
Vue.prototype.$notification = notification

// mount axios to `Vue.$http` and `this.$http`
Vue.use(VueAxios)

Vue.use(Icon)
Vue.use(Button)
Vue.use(Layout)
Vue.use(Menu)
Vue.use(Row)
Vue.use(Col)
Vue.use(Affix)
Vue.use(Popover)
Vue.use(Card)
Vue.use(Carousel)
Vue.use(Divider)
Vue.use(ConfigProvider)
Vue.use(Result)
Vue.use(Spin)
Vue.use(Table)
Vue.use(Drawer)

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: queryString.parse(location.search).locale || 'zhCN',
  messages: {
    zhCN: {
      message: zhCN,
    },
    enUS: {
      message: enUS,
    },
  },
})

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
