module.exports = {
  dataZH: {
    baseInfo: {
      phone: '+86-15833463803 或 +86-18131562111',
      qq: '2436548842',
      address:
        '河北唐山市路南区女织寨镇正泰里春晓b46商铺4幢1单元203号(春晓1期204栋对面6层楼)',
      email:
        'liyinfei@tshjdz.com 或 liujiahang@tshjdz.com 或 wujunwei@tshjdz.com',
    },
    home: {
      banners: [
        {
          img: require('@/assets/home//banner-1.jpg'),
        },
        {
          img: require('@/assets/home//banner-2.jpg'),
        },
      ],
    },
    serviceFeature: {
      img: require('@/assets/home/service_feature.jpg'),
      feature: [
        {
          title: '专家指挥',
          content: '提供专业的模块解决方案。',
        },
        {
          title: '一站采购',
          content: '最大限度地节约采购成本和时间。',
        },
        {
          title: '快捷方便',
          content: '我们配套了方便快捷的运输系统。',
        },
        {
          title: '质量保证',
          content: '提供紧固件国家权威机构检测报告。',
        },
        {
          title: '实现共赢',
          content: '“共发展，同盈利”理念的忠实践行者。',
        },
      ],
    },
    company: {
      name: '唐山宏佳电子科技有限公司',
      companySmallPic: require('@/assets/home/company_introduce.jpg'),
      contactQRText: '扫描二维码进入宏佳电子淘宝店',
      introduce: [
        '唐山宏佳电子科技有限公司成立于2014年4月22日，是一家从事电子、通信与自动控制技术研究服务，计算机科学技术研究服务，基础软件开发及经营,嵌入式软件开发及经营，计算机、贸易咨询服务。',
      ],
      feature: [
        '1、我公司产品均为自主研发，功能完美、性能强大',
        '2、有传输距离远、尺寸小、耗电少等特点。',
        '共享、共赢是我公司全体员工前进的方向；品质、性能是我公司产品质量的保证；诚信务实发展、积极开拓进取是我公司经营理念。唐山宏佳电子科技有限公司期待与您携手并进，共创辉煌。',
      ],
      culture: [
        '企业理念：品质  服务  需求  共赢',
        '核心价值观：创意   创新   创造',
        '企业战略观：前瞻   独到   领跑思想',
        '服务价值观：不求最好  只求更好  不求做的更远 只有离您最近',
        '企业精神：承载个人理想  追求企业进步  创造美好生活',
        '行为准则：永不满足  打造经典  做精做强  尽善尽美',
        '团队理念：求同存异  团结协作  荣辱与共',
      ],
    },

    cooperation: [
      {
        img: require('@/assets/cooperation/dialog.jpg'),
        title: 'dialog',
        content:
          '2014年与Dialog建立战略合作，目前已成为Dialog蓝牙产品线中国区前三大客户，目前与Dialog合作更加紧密。高度集成电源管理、AC/DC电源转换、固态照明（SSL）和蓝牙低功耗（Bluetooth® low energy）技术供应商Dialog半导体公司（德国证券交易所交易代码：DLG）今天宣布，推出为可充电设备提供连接性的全球集成度最高的单芯片解决方案SmartBond™ DA14681，可用于可穿戴设备、智能家居、以及其他新兴物联网（IoT）设备。',
      },
      {
        img: require('@/assets/cooperation/tcl.png'),
        title: 'TCL通力电子',
        content:
          'TCL集团股份有限公司创立于1981年，是全球化的智能产品制造及互联网应用服务企业集团。集团现有7万名员工，26个研发中心,10余家联合实验室，22个制造加工基地，在80多个国家和地区设有销售机构，业务遍及全球160多个国家和地区。其前身为中国首批13家合资企业之一——TTK家庭电器（惠州）有限公司，从事录音磁带的生产制造，后来拓展到电话、电视、手机、冰箱、洗衣机、空调、小家电、液晶面板等领域。《财富》中文版于北京时间2017年7月31日发布了最新的《财富》中国500强排行榜，TCL集团股份有限公司在2017年中国500强排行榜（公司名单）中排名第64位。',
      },
      {
        img: require('@/assets/cooperation/nordic.jpeg'),
        title: 'Nordic',
        content:
          '2019年开始与NORDIC正式成为官方全球战略合作伙伴，目前已经成为NORDIC，北方最大的蓝牙模组与解决方案厂商',
      },
      {
        img: require('@/assets/cooperation/sanwa.png'),
        title: '日本三和株式会社',
        content:
          'SANWA 株式会社成立于昭和26年(1951年) ，年营业额达220多亿日币。从事耳机，鼠标，键盘，电脑包，办公座椅等电脑周边产品的销售，目前贩卖商品达8000余种，合作客户达10000余家，已经发展成为日本著名电脑配件品牌',
      },
      {
        img: require('@/assets/cooperation/huoniweier.png'),
        title: '霍尼韦尔',
        content:
          '霍尼韦尔是一家国际性从事自控产品开发及生产的公司，公司成立于一八八五年，有超过百年历史的国际公司，一九九六年，被美国"财富"杂志评为最受推崇的20家高科技企业之一。是一家销售额为333.7亿美元(2011年度)，在多元化技术和制造业方面占世界领导地位的跨国公司（500强排名280位）。宗旨是以增加舒适感，提高生产力，节省能源，保护环境，保障使用者生命及财产从而达到互利增长为目的。为全球的楼宇，工业，航天及航空市场的客户服',
      },
      {
        img: require('@/assets/cooperation/sanlingdianji.png'),
        title: '三菱电机',
        content:
          '三菱电机是制造和销售电气与电子产品和系统的世界领先品牌企业之一，其产品和系统在众多领域和应用中得到广泛使用。',
      },
      {
        img: require('@/assets/cooperation/tujia.png'),
        title: '途家',
        content:
          '途家网，系全球公寓民宿预订平台，于2011年12月1日正式上线。作为中国民宅分享的引领者，途家致力于为房客提供丰富优质的、更具家庭氛围的出行住宿体验，又为房东提供高收益且有保障的闲置房屋分享平台。',
      },
    ],
  },
  dataEN: {
    baseInfo: {
      phone: '+86-15833463803 or +86-18131562111',
      qq: '2436548842',
      address:
        'Hebei Tangshan City Lushuihe District Nvzhi Zhai Town Zhengtai Li Chunxiao B46 Shop, Building 4, Unit 1, Room 203 (Opposite to Building 204 of Chunxiao Phase 1, 6-story building)',
      email:
        'liyinfei@tshjdz.com or liujiahang@tshjdz.com or wujunwei@tshjdz.com',
    },
    home: {
      banners: [
        {
          img: require('@/assetsEN/home/banner-1.jpg'),
        },
        {
          img: require('@/assetsEN/home/banner-2.jpg'),
        },
      ],
    },
    serviceFeature: {
      img: require('@/assetsEN/home/service_feature.jpg'),
      feature: [
        {
          title: 'Expert command',
          content: 'Provide professional module solutions。',
        },
        {
          title: 'One-stop shopping',
          content: 'Maximize savings in procurement costs and time.',
        },
        {
          title: 'fast and convenient',
          content:
            'We are equipped with a convenient and fast transportation system.',
        },
        {
          title: 'Quality assurance',
          content: 'Provide test report of fasteners authority.',
        },
        {
          title: 'Achieve win-win',
          content:
            'Loyal practitioners of the concept of co - development and profit.',
        },
      ],
    },
    company: {
      name: 'Tangshan Hongjia Electronic Technology Co., Ltd.',
      companySmallPic: require('@/assetsEN/home/company_introduce.jpg'),
      contactQRText:
        'Scan the QR code to enter Hongjia Electronic Taobao Store',
      introduce: [
        'Tangshan Hongjia Electronic Technology Co., Ltd. was established on April 22, 2014. It is a company engaged in electronic, communication and automatic control technology research services, computer science and technology research services, basic software development and management, embedded software development and management, computer, trade consultation service.',
      ],
      feature: [
        '1. The products of our company are independently developed, with perfect functions and powerful performance',
        '2. It has the characteristics of long transmission distance, small size and low power consumption.',
        "Sharing and win-win is the direction of all employees of our company; quality and performance are the guarantee of our company's product quality; integrity and pragmatic development, and active development are our company's business philosophy. Tangshan Hongjia Electronic Technology Co., Ltd. looks forward to working hand in hand with you to create brilliant future.",
      ],
      culture: [
        'Corporate philosophy: quality, service, demand, win-win',
        'Core values: creativity, innovation, creation',
        'Corporate strategy view: forward-looking, unique and leading thought',
        'Service values: do not seek the best, seek better, do not seek to go further, only the nearest to you',
        'Entrepreneurial spirit: carrying personal ideals, pursuing corporate progress and creating a better life',
        'Code of Conduct: Never Satisfy Creating Classics, Doing Fine and Strong, Perfect',
        'Team concept: seek common ground while reserving differences, unite and cooperate, honor and common',
      ],
    },

    cooperation: [
      {
        img: require('@/assetsEN/cooperation/dialog.jpg'),
        title: 'dialog',
        content:
          "Established a strategic cooperation with Dialog in 2014, and has now become the top three customers of Dialog's Bluetooth product line in China, and is currently working more closely with Dialog. Highly integrated power management, AC / DC power conversion, solid-state lighting (SSL) and Bluetooth® low energy (Bluetooth® low energy) technology provider Dialog Semiconductor (Deutsche Börse trading symbol: DLG) today announced the launch of rechargeable The device provides the world's most integrated single-chip solution SmartBond ™ DA14681, which can be used in wearable devices, smart homes, and other emerging Internet of Things (IoT) devices.",
      },
      {
        img: require('@/assetsEN/cooperation/tcl.png'),
        title: 'TCL KONE Electronics',
        content:
          'Founded in 1981, TCL Group Co., Ltd. is a global intelligent product manufacturing and Internet application service enterprise group. The Group currently has 70,000 employees, 26 R & D centers, more than 10 joint laboratories, 22 manufacturing and processing bases, sales offices in more than 80 countries and regions, and operations in more than 160 countries and regions around the world. Its predecessor was one of the first 13 joint ventures in China-TTK Home Appliances (Huizhou) Co., Ltd., which was engaged in the production of audio tapes, and later expanded to telephones, TVs, mobile phones, refrigerators, washing machines, air conditioners, small household appliances, LCD panels And other fields. The Chinese version of "Fortune" released the latest "Fortune " China Top 500 list on July 31, 2017, Beijing time. TCL Group Co., Ltd. ranked 64th in the 2017 China Top 500 list (company list).',
      },
      {
        img: require('@/assetsEN/cooperation/nordic.jpeg'),
        title: 'Nordic',
        content:
          'In 2019, it officially became the official global strategic partner with NORDIC. Now it has become NORDIC, the largest Bluetooth module and solution manufacturer in the north.',
      },
      {
        img: require('@/assetsEN/cooperation/sanwa.png'),
        title: 'Japan Sanwa Co., Ltd.',
        content:
          'SANWA Co., Ltd. was established in Showa 26 (1951) with an annual turnover of more than 22 billion yen. Engaged in the sale of computer peripheral products such as headsets, mice, keyboards, computer bags, office seats, etc. Currently, there are more than 8,000 kinds of merchandise sold and more than 10,000 cooperative customers.',
      },
      {
        img: require('@/assetsEN/cooperation/huoniweier.png'),
        title: 'Honeywell',
        content:
          'Honeywell is an international company engaged in the development and production of automatic control products. The company was established in 1885, an international company with a history of more than 100 years. In 1996, it was rated by the US magazine "Fortune" One of the 20 most highly regarded high-tech companies. It is a multinational company with sales of US $ 33.37 billion (2011) and a world leader in diversified technology and manufacturing (ranked 280 in the Top 500). The aim is to increase comfort, increase productivity, save energy, protect the environment, protect users\' lives and property and achieve mutually beneficial growth. Serve customers in the global building, industrial, aerospace and aviation markets',
      },
      {
        img: require('@/assetsEN/cooperation/sanlingdianji.png'),
        title: 'Mitsubishi Electric',
        content:
          "Mitsubishi Electric is one of the world's leading brand companies that manufacture and sell electrical and electronic products and systems. Its products and systems are widely used in many fields and applications.",
      },
      {
        img: require('@/assetsEN/cooperation/tujia.png'),
        title: 'Tujia',
        content:
          'Tujia had become a global apartment booking platform, was officially launched on December 1, 2011. As a leader in Chinese house sharing, Tujia is committed to providing tenants with a rich and high-quality, more family-like travel accommodation experience, and providing landlords with a high-yield and guaranteed idle housing sharing platform.',
      },
    ],
  },
}
